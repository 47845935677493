.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  height: 72px;
  border-bottom: 1px solid rgba(249, 253, 240, 0.1);
  background: #1d232f;
  /* padding-left: calc(255px + 28px);
  padding-right: 28px;
  max-width: 1185px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.route {
  color: rgba(249, 253, 240, 0.4);
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-transform: capitalize;
}
.highlight {
  color: rgba(249, 253, 240, 1);
}
.iconContainer {
  display: flex;
  align-items: center;
  gap: 18px;
}
.icon {
  font-size: 28px;
  color: rgba(249, 253, 240, 1);
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 1399px) {
  .icon {
    display: block;
  }
}
@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 520px) {
  .iconContainer {
    gap: 10px;
  }
}
@media only screen and (max-width: 520px) {
  .icon {
    font-size: 24px;
  }
}
