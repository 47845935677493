.wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 18px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  color: rgba(249, 253, 240, 0.4);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media only screen and (max-width: 380px) {
  .wrapper {
    padding: 18px 10px;
  }
  .text {
    font-size: 12px;
  }
}
