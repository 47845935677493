.task {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.alreadyJoined {
  position: relative;
  opacity: 0.5;
  cursor: not-allowed;
}
.alreadyJoined::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(249, 253, 240, 0.05);
}
.header {
  display: flex;
  align-items: center;
  gap: 15px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 15px;
  /* background-color: rgba(56, 176, 227, 1); */
}
.icon {
  color: #fff;
  font-size: 28px;
}
.nameAndUserName {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.name {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.571%;
  opacity: 0.800000011920929;
}
.userName {
  color: #eef9d5;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.2px;
  opacity: 0.30000001192092896;
}
.infoContainer {
  border-radius: 12px;
  background: rgba(249, 253, 240, 0.05);
  padding: 12px;
}
.info {
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 145.455%;
  /* opacity: 0.30000001192092896; */
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background: #1ee0ac;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 138.462%;
  padding: 12px 15px;
  transition: 0.3s;
  margin-top: auto;
}
.button:hover {
  background-color: #1ee0ac;
  color: rgba(53, 72, 12, 1);
}
.alreadyJoined .button {
  cursor: not-allowed;
  color: rgba(249, 253, 240, 0.5);
  background: rgba(249, 253, 240, 0.05);
}
.alreadyJoined .button:hover {
  cursor: not-allowed;
  color: rgba(249, 253, 240, 0.5);

  background: rgba(249, 253, 240, 0.05);
}
@media only screen and (max-width: 991px) {
  .task {
    padding: 15px;
  }
}
