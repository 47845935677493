.refferedUserWrapper {
  border-radius: 16px;
  background: rgba(249, 253, 240, 0.05);
  padding: 18px;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 15px;
}
.header {
  padding: 12px 0;

  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 20px;
  border-bottom: 1px solid rgba(249, 253, 240, 0.4);
}
.userWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 18px;
}
.userInfo {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 20px;
}

.text {
  color: rgba(249, 253, 240, 0.4);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.name {
  overflow: hidden;
  color: rgba(249, 253, 240, 0.8);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
@media only screen and (max-width: 991px) {
  .refferedUserWrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .refferedUserWrapper {
    width: 100%;
  }
}
