.wrapper {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 18px 3px;
  padding-bottom: 0;
  position: relative;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  padding: 0 18px;
  padding-top: 5px;
  padding-bottom: 7px;
}

.tableContainer {
  border-radius: 22px;
  padding-bottom: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  max-height: 285px;
}
.tableContainer:hover {
  overflow-y: auto;
}
.table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
  width: 100%;
}
.header {
  background-color: #282e39;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(249, 253, 240, 0.1);

  z-index: 1;
}

.heading,
.item {
  text-align: left;
  padding: 10px 15px;
  white-space: nowrap;
}
.heading {
  color: #1ee0ac;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.item {
  color: #fff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.date {
  color: rgba(249, 253, 240, 0.4);
  font-size: 15px;
}
/* .heading:last-child {
  text-align: center;
}
.item:last-child {
  text-align: center;
  display: flex;
  justify-content: center;
} */
.downloadIcon {
  color: rgba(249, 253, 240, 1);
  font-size: 20px;
  cursor: pointer;
}
/* .overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 110px;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(0deg, #282e39 0%, rgba(40, 46, 57, 0) 100%);
} */
/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: rgba(249, 253, 240, 1) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: rgba(249, 253, 240, 1);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
.complete {
  color: #1ee0ac;
}
.pending {
  color: #ffff00;
}
.faild {
  color: #ff0000;
}
