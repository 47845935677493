.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
