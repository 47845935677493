.withDrawWrapper {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
}
.amountAllowed {
  padding: 18px;
}
.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  opacity: 0.800000011920929;
  padding-bottom: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progressText {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  opacity: 0.800000011920929;

  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 520px) {
  .amountAllowed {
    padding: 18px 14px;
  }
}
