.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  height: 72px;
  border-bottom: 1px solid rgba(249, 253, 240, 0.1);
  background: #1d232f;
  /* padding-left: calc(255px + 28px);
  padding-right: 28px;
  max-width: 1185px; */
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid rgba(249, 253, 240, 0.08);
  padding: 10px 0;
  margin-top: auto;
}
.items {
  display: flex;
  align-items: center;
  gap: 22px;
}
.text {
  color: #fff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
@media only screen and (max-width: 1199px) {
  .footer {
    padding: 0 18px;
  }
}

@media only screen and (max-width: 520px) {
  .footer {
    padding: 0 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
