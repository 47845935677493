@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background: #1d232f;

  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

.mainContainer {
  padding-left: calc(255px + 28px);
  padding-right: 28px;
}
.mainWrapper {
  max-width: 1185px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh);
}
@media only screen and (max-width: 1399px) {
  .mainContainer {
    padding: 0 18px;
  }
}

@media only screen and (max-width: 520px) {
  .mainContainer {
    padding: 0 10px;
  }
}
