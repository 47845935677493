.sidebar {
  position: fixed;
  left: 0px;
  top: 0px;
  border-right: 1px solid rgba(249, 253, 240, 0.1);
  background: #1d232f;

  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 30px 20px;
  padding-bottom: 20px;
  padding-top: 72px;
  max-width: 254px;
  width: 100%;
  height: 100vh;
  z-index: 5;
  overflow-y: auto;
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.navItem {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: rgba(249, 253, 240, 0.7);

  display: flex;
  align-items: center;
  gap: 6px;

  padding: 12px 12px;
}
.navActive {
  border-radius: 14px;
  background: rgba(249, 253, 240, 0.1);
}
.navActive.navItem {
  color: rgba(249, 253, 240, 1);
}
.icon {
  width: 18.33px;
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #1ee0ac;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 16px;
  color: rgba(53, 72, 12, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 138.462%;
  padding: 12px 15px;
  transition: 0.3s;
  margin-top: auto;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: #fff rgba(250, 250, 250, 0.2);
}
/* For Firefox */
/* For Firefox */

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.sidebar::-webkit-scrollbar-thumb {
  background: #fff;

  border-radius: 100%;
  border: none;
}
@media only screen and (max-width: 1399px) {
  .sidebar {
    transform: translateX(-150%);
    transition: 0.3s;
    left: 0;
    top: 0;
    min-height: 100vh;
  }
  .showSidebar {
    transform: translateX(0%);
    background: #151c33;

    transition: 0.3s;
  }
}
@media only screen and (max-width: 520px) {
  .sidebar {
    padding-top: 30px;
  }
  .navItems {
    gap: 8px;
  }
  .navItem {
    font-size: 15px;
  }
}
