.wrapper {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.title {
  color: #f9fdf0;
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

  /* 24 Semibold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.tagline {
  color: rgba(249, 253, 240, 0.4);
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.helpRoute {
  color: #95a4fc;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  color: #f9fdf0;
  opacity: 0.800000011920929;

  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  padding-bottom: 8px;
}
.highlight {
  color: #1ee0ac;
}

.input {
  color: rgba(249, 253, 240, 0.8);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  padding: 9px 12px;
  border-radius: 10px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: rgba(249, 253, 240, 0.05);
  width: 100%;
  outline: none;
}
.dropDownWrapper {
  border-radius: 10px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: rgba(249, 253, 240, 0.05);
  width: 100%;

  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 5px;
}
.dropDownContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 9px 14px;
  position: relative;
}

.networksContainer {
  border-left: 1px solid rgba(249, 253, 240, 0.1);
  padding-left: 19px;
}

.itemWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
}
.prefix {
  color: rgba(249, 253, 240, 0.4);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 2px;
}
.coinName,
.networkName {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  cursor: pointer;
  width: 100%;
}
.arrow {
  font-size: 18px;
  color: rgba(249, 253, 240, 1);
  cursor: pointer;
}
.dropDownItems {
  position: absolute;
  top: 45px;
  left: 0;
  min-width: 100%;
  z-index: 5;
  padding: 9px 14px;
  border-radius: 10px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: #323942;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dropDownItems .itemWrapper {
  cursor: pointer;
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  /* background-color: #1ee0ac; */
  background-color: #1ee0ac;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 15px;
  color: #000;
  font-style: normal;
  font-weight: 600;
  line-height: 138.462%;
  padding: 10px 15px;
  transition: 0.3s;
}
@media only screen and (max-width: 575px) {
  .dropDownWrapper {
    grid-template-columns: 1fr;
    gap: 10px;
    border-radius: 0px;
    border: none;
    background: transparent;
  }
  .dropDownContainer {
    border-radius: 10px;
    border: 1px solid rgba(249, 253, 240, 0.1);
    background: rgba(249, 253, 240, 0.05);
  }
}
@media only screen and (max-width: 380px) {
  .wrapper {
    padding: 18px 12px;
  }
}
