.referelProgramWrapper {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: rgba(249, 253, 240, 0.05);
  position: relative;
}
.link {
  overflow: hidden;
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.icon {
  font-size: 20px;
  color: rgba(249, 253, 240, 1);
  cursor: pointer;
}
.copied {
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  background-color: rgba(249, 253, 240, 1);
  border-radius: 6px;
  font-size: 10px;
  font-family: "Inter";
  padding: 3px;
  z-index: 1;
}
.textAndImage {
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.text {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 183.333%;
}
.image {
  max-width: 147px;
}
/* @media only screen and (max-width: 1399px) {
  .referelProgramWrapper {
    gap: 12px;
  }
  .linkContainer {
    padding: 6px 10px;
  }
  .link {
    font-size: 12px;
  }
  .text {
    font-size: 11px;
  }
  .image {
    max-width: 135px;
  }
} */
@media only screen and (max-width: 520px) {
  .textAndImage {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .linkContainer {
    padding: 6px 10px;
  }
  .link {
    font-size: 11px;
  }
}
