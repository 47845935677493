.leaderBoard {
  width: 100%;
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.filteringSection {
  border-radius: 14px;
  background: rgba(249, 253, 240, 0.05);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.left {
  display: flex;
  align-items: center;
  gap: 18px;
}
.upDownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(249, 253, 240, 0.07);
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.icon {
  color: rgba(249, 253, 240, 1);
  font-size: 20px;
  cursor: pointer;
}
.sortIcon {
  color: rgba(249, 253, 240, 1);

  cursor: pointer;
  font-size: 28px;
}
.text {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-left: 18px;
  border-left: 1px solid rgba(249, 253, 240, 0.4);
}
.searchContainer {
  border-radius: 8px;
  border: 1px solid rgba(249, 253, 240, 0.04);
  background: rgba(249, 253, 240, 0.05);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  max-width: 247px;
  width: 100%;
}
.searchIcon {
  color: rgba(249, 253, 240, 0.6);
  font-size: 20px;
}
.input {
  color: #fff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  outline: none;
  background: transparent;
  border: none;
  width: 100%;
}
.tableContainer {
  padding-bottom: 5px;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.header {
  border-bottom: 1px solid rgba(249, 253, 240, 0.05);
}
.heading,
.item {
  text-align: left;
  padding: 12px 15px;
  white-space: nowrap;
}
.heading {
  color: #fff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.row {
  width: 100%;
  border-bottom: 1px solid rgba(249, 253, 240, 0.05);
}

.item {
  color: #1ee0ac;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border: 0px solid rgba(249, 253, 240, 0.05);
}
.firstRank,
.secondRank,
.thirdRank {
}
.firstRank .item {
  background: rgba(255, 200, 4, 0.26);
  padding: 20px 15px;
  font-weight: 600;
}
.secondRank .item {
  background: rgba(249, 253, 240, 0.12);
  padding: 16px 15px;
}
.thirdRank .item {
  background: rgba(134, 82, 5, 0.18);
  padding: 13px 15px;
}
.rejectedRow .item {
  background: rgba(249, 253, 240, 0.05);
}

.item:first-child {
  border-left-style: solid;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.item:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}
.firstRank .rank,
.secondRank .rank,
.thirdRank .rank {
  font-size: 16px;
  font-weight: 700;
}
.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.userImage {
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.circle {
  font-size: 13px;
}
.inProgress {
  color: rgba(138, 140, 217, 1);
}
.complete {
  color: #1ee0ac;
}
.pending {
  color: #59a8d4;
}
.approved {
  color: #59a8d4;
}
.rejected {
  color: rgba(249, 253, 240, 0.4);
}
/* tr:nth-child(even) {
  background-color: #1a1a1a;
} */

/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #1ee0ac rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #1ced86;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .filteringSection {
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 12px;
  }
  .left {
    width: 100%;
  }
  .searchContainer {
    max-width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .titleAndSearchBox {
    flex-direction: column;
    padding-bottom: 30px;
  }
  .left {
    gap: 10px;
  }
}
