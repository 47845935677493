.progressBar {
  display: flex;
  align-items: center;
  padding: 0 4px;

  height: 16px;
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: background-color 0.5s ease-out;
}

.progress {
  height: 100%;
  background: rgba(171, 171, 222, 1);
  border-radius: 10px;
  height: 8px;
  transition: width 0.5s ease-out;
  border-radius: 8px;
}
