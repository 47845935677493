.withDrawWrapper {
  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
}
.amountAllowed {
  padding: 18px;
  padding-bottom: 26px;
  border-bottom: 1px solid rgba(249, 253, 240, 0.08);
}
.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progressText {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withdrawMoneyWrapper {
  padding: 18px;
}
.withdrawMoneyContainer {
  border-radius: 14px;
  background: rgba(249, 253, 240, 0.05);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  margin-top: 14px;
}

.tagline {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 6px;
}
.icon {
  font-size: 20px;
  color: rgba(249, 253, 240, 1);
}
.button {
  color: #35480c;
  text-align: right;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  border-radius: 8px;
  background: #fff;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: auto;
}
