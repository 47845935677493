.pagintionContainer {
  margin-top: auto;
  margin-top: auto;
  align-self: flex-end;
  padding-bottom: 18px;
}

.myPagination {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
}
.myPageNumber {
  list-style: none;

  border-radius: 6px;
  padding: 10px 14px;
  border-radius: 10px;
  background: rgba(249, 253, 240, 0.05);
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.myPageNumber a {
  color: rgba(249, 253, 240, 0.6);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.activeMyPagenumber {
  border-radius: 10px;
  background: rgba(171, 222, 61, 0.95);
}
.activeMyPagenumber a {
  color: rgba(53, 72, 12, 1);
}
@media only screen and (max-width: 520px) {
  .pagintionContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .myPagination {
    gap: 4px;
  }
  .myPageNumber {
    min-width: 35px;
    padding: 6px 10px;
  }
  .myPageNumber a {
    font-size: 12px;
  }
}
