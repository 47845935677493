.wrapper {
  width: 100%;

  border-radius: 20px;
  background: rgba(249, 253, 240, 0.05);
  padding: 5px;
  font-family: Inter;
}

/* Inventory chart navigation */
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
}

/* Inventory chart positioning */
.chartWrapper {
  width: calc(100% - 12px);
}
.inveChart {
  width: 100%;
  padding: 0 0 16px 2px;
}
