.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 18px;
  padding-bottom: 28px;
  border-bottom: 1px solid rgba(249, 253, 240, 0.08);
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
