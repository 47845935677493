.generateIncome {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 24px;
}
.taskContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.filteringSection {
  border-radius: 14px;
  background: rgba(249, 253, 240, 0.05);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.left {
  display: flex;
  align-items: center;
  gap: 18px;
}
.upDownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(249, 253, 240, 0.07);
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.icon {
  color: rgba(249, 253, 240, 1);
  font-size: 20px;
  cursor: pointer;
}
.sortIcon {
  color: rgba(249, 253, 240, 1);

  cursor: pointer;
  font-size: 28px;
}
.taskLeft {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-left: 18px;
  border-left: 1px solid rgba(249, 253, 240, 0.4);
}
.searchContainer {
  border-radius: 8px;
  border: 1px solid rgba(249, 253, 240, 0.04);
  background: rgba(249, 253, 240, 0.05);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  max-width: 247px;
  width: 100%;
}
.searchIcon {
  color: rgba(249, 253, 240, 0.6);
  font-size: 20px;
}
.input {
  color: rgba(249, 253, 240, 1);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  outline: none;
  background: transparent;
  border: none;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .taskContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .taskContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .filteringSection {
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 12px;
  }
  .left {
    width: 100%;
  }
  .searchContainer {
    max-width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .taskContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
