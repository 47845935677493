.balanceContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.balanceBox {
  border-radius: 20px;
  background: rgba(66, 72, 84, 0.8);
  padding: 18px 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.activeBalanceBox {
  background: #c8c8ea;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.title {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
}

.icon {
  color: rgba(249, 253, 240, 1);
  font-size: 24px;
  display: flex;
  align-items: center;
}

.balance {
  color: #f9fdf0;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.activeBalanceBox .title,
.activeBalanceBox .icon,
.activeBalanceBox .balance {
  color: rgba(47, 47, 122, 1);
}
/* @media only screen and (max-width: 1399px) {
  .balanceContainer {
    gap: 20px 16px;
  }
  .balanceBox {
    gap: 8px;
    padding: 18px 15px;
  }
  .title {
    font-size: 13px;
  }
  .icon {
    font-size: 21px;
  }
  .balance {
    font-size: 18px;
  }
} */
@media only screen and (max-width: 520px) {
  .balanceContainer {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
