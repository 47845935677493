.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  min-height: 100vh;
  background: #1d232f;
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
}
.unsCommunityContainer {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  height: 408.41px;
}
.unsCommunity {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
  border-radius: 20px;
  display: block;
  padding: 0;
}
.loginContainer {
  border-radius: 20px;
  background: #f9fdf00d;

  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7%;
}
.logo {
  width: 150px;
  margin: 0 auto;
  display: block;
}
.form {
  max-width: 388px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.title {
  color: rgba(255, 255, 225, 1);
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.tagline {
  color: rgba(255, 255, 225, 1);
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.loginWithContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 10px;
}
.loginWithButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: rgba(249, 253, 240, 0.05);
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
}
.aplleIcon {
  color: rgba(255, 255, 225, 1);
  font-size: 20px;
}
.loginWithText {
  color: rgba(255, 255, 225, 1);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.orWithEmail {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 225, 1);
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

  font-family: Inter;
  font-size: 12px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.orWithEmail::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1px;
  width: calc(50% - 50px);
  background: rgba(249, 253, 240, 0.1);
}
.orWithEmail::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 1px;
  width: calc(50% - 50px);
  background: rgba(249, 253, 240, 0.1);
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.input {
  color: rgba(249, 253, 240, 1);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  padding: 9px 12px;
  border-radius: 10px;
  border: 1px solid rgba(249, 253, 240, 0.1);
  background: rgba(249, 253, 240, 0.05);
  width: 100%;
  outline: none;
}
.forgotPassword {
  margin-left: auto;
  cursor: pointer;
}
.highlightedText {
  color: #1ee0ac;
  text-align: right;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #1ee0ac;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 13px;
  color: rgba(53, 72, 12, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 138.462%;
  padding: 12px 15px;
  transition: 0.3s;
}
.notLoginYet {
  color: #fff;
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
  .unsCommunityContainer {
    display: none;
  }
  .loginContainer {
    max-width: 680px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 520px) {
  .loginWithContainer {
    flex-direction: column;
  }
  .logo {
    width: 120px;
  }
  .loginContainer {
    padding: 22px 15px;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.7; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: fff;
}
